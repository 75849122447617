define(['app', '$window'], (app, $window) => {

  const productRecommender = () => {
    const DATA_ERROR = 'data-error';
    const component = {};

    component.config = {
      selectors: {
        reloadQuizButton: '.productRecommenderErrorSearchResult_cta_reload'
      },
      channels: {
        quizAnswers: 'quiz/quizAnswers'
      }
    }

    component.init = (element) => {
      component.element = element;
      component.profileKey = component.element.querySelector("[name=profile_key]").value;
      component.csrfToken = component.element.querySelector("[name=csrf_token]").value;

      component.subscribe();
    }

    component.subscribe = () => {
      app.subscribe(component.config.channels.quizAnswers, component.quizAnswersReceived);
    };

    component.bind = () => {
      component.reloadQuizButton.addEventListener('click', component.reloadQuiz);
    }

    component.quizAnswersReceived = (quizAnswers) => {
      component.quizAnswers = quizAnswers;
      component.selectedAnswers = [];
      Array.from(quizAnswers.entries()).forEach(([name, values])=> {
        for (let value of values) {
          component.selectedAnswers.push(`profiles:${encodeURIComponent(name)}=${encodeURIComponent(value)}`);
        }
      });

      const formData = `profile_key=${component.profileKey}&csrf_token=${component.csrfToken}&submission=&${component.selectedAnswers.join("&")}`;


      app.ajax.post({
        url: "/submit.profile?enableProductRecommender=true",
        send: formData,
        requestHeader: {
          header: 'Content-Type',
          value: 'application/x-www-form-urlencoded'
        },
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    component.successHandler = (response) => {
      $window.location.href = response;
    };

    component.errorHandler = (response) => {

      component.element.innerHTML = response;
      component.element.setAttribute(DATA_ERROR, '');
      component.reloadQuizButton = component.element.querySelector(component.config.selectors.reloadQuizButton);
      component.bind();

    };

    component.reloadQuiz = () => {
      component.quizAnswersReceived(component.quizAnswers)
    }

    return component;

  };

  return productRecommender;
});
